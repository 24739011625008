import React, { useState, useEffect } from 'react'
import { Select, Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes, observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { Option } = Select

const TableDNDRow = ({ item, className, readOnly, onChange, isMobile, onClick }) => {
  const [data, setData] = useState({})
  const [selectedRevision, setSelectedRevision] = useState('')
  useEffect(() => {
    setData(item)
    setSelectedRevision(item.selectedRevision)
  }, [item.id])

  const handleChangeRevision = (value) => {
    setSelectedRevision(value)
    onChange(value)
  }

  const renderRevisionDropDown = revisions => (
    <Select value={selectedRevision} defaultValue={revisions[0]} style={{ width: '90%' }} onChange={handleChangeRevision}>
      {
      revisions?.map(revision => (
        <Option value={revision} key={revision}>
          {revision}
        </Option>
      ))
    }
    </Select>
  )
  console.log(item)

  return !readOnly ?
    (
      <Row onClick={onClick} justify="center" align="middle" className={`drag-and-drop-row ${className}`} key={data.id}>
        <Col span={2}>
          <FontAwesomeIcon
            icon={['fal', 'grip-lines']}
            style={{ marginLeft: '0.2rem' }}
          />
        </Col>
        <Col span={8}>
          {data.name}
        </Col>
        <Col span={isMobile ? 9 : 5}>
          {data.lastRevised}
        </Col>
        <Col span={isMobile ? 0 : 5}>
          {data.by}
        </Col>
        <Col span={isMobile ? 5 : 4}>
          {renderRevisionDropDown(data.revisions || [])}
        </Col>
      </Row>
    ) :
    (
      <Row onClick={onClick} justify="center" align="middle" className={`drag-and-drop-row ${className}`} key={data.id}>
        <Col span={10}>
          {data.name}
        </Col>
        <Col span={isMobile ? 9 : 5}>
          {data.lastRevised}
        </Col>
        <Col span={isMobile ? 0 : 5}>
          {data.by}
        </Col>
        <Col span={isMobile ? 5 : 4}>
          {data.revision}
        </Col>
      </Row>
    )
}

TableDNDRow.propTypes = {
  item: MobxPropTypes.objectOrObservableObject.isRequired,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  isMobile: PropTypes.bool,
  onClick: PropTypes.func,
}

TableDNDRow.defaultProps = {
  className: '',
  readOnly: false,
  onChange: () => {},
  isMobile: false,
  onClick: () => {},
}

export default observer(TableDNDRow)
