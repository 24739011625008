import React from 'react'

import { useStores } from 'flynk.app.web.core.components/helpers/common'

import { observer } from 'mobx-react'
import TestsMenuHeader from '../TestsMenuItem/TestsMenuHeader'
import TestsMenuItems from '../TestsMenuItem/TestsMenuItems'
import TestsMenuSearch from '../TestsMenuItem/TestsMenuSearch'
import { TestObjectType } from '../../../constants/test'

const TestsCaseMenu = () => {
  const {
    testStore: {
      cases,
    },
  } = useStores()
  return (
    <>
      <TestsMenuHeader formName="case" name="Cases" bottomLineColor="white" canAddNewItem />
      <TestsMenuSearch />
      <TestsMenuItems itemType={TestObjectType.TestCase} items={cases} selectedBgColor="white" />
    </>
  )
}

export default observer(TestsCaseMenu)
