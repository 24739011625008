import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import TurndownService from 'turndown'
import { Button, Col, Row, Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const { Text, Title } = Typography
const TestNote = ({
  placeholder,
  readOnly,
  isMobile,
  goBack,
}) => {
  const [noteContent, setNoteContent] = useState('')

  const [isDirty, setIsDirty] = useState(false)

  const turndownService = new TurndownService()

  turndownService.addRule('hugeText', {
    filter(node) {
      return node.className === 'ql-size-huge'
    },
    replacement(content) {
      return `# ${content}`
    },
  })

  turndownService.addRule('largeText', {
    filter(node) {
      return node.className === 'ql-size-large'
    },
    replacement(content) {
      return `## ${content}`
    },
  })

  turndownService.addRule('smallText', {
    filter(node) {
      return node.className === 'ql-size-small'
    },
    replacement(content) {
      return `#### ${content}`
    },
  })

  //   const convertHTMLToMarkdown = htmlText =>
  // (htmlText ? turndownService.turndown(htmlText) : '')
  //   const convertMarkdownToHtml = (mdText) => {
  //     const converter = new showdown.Converter()
  //     return converter.makeHtml(mdText)
  //   }

  const handleChangeNote = (value) => {
    setNoteContent(value)
    setIsDirty(true)
  }

  return (
    <div>
      <Row>
        <Col span={16}>
          <Row align="middle">
            <Title level={5} type="secondary">TEST STEP: </Title>
            <Text className="ml-10 test-note-text"> Step 234</Text>
          </Row>
          <Row>
            <Text type="secondary">
              A description of how to perform this step
            </Text>
          </Row>
        </Col>
        <Col span={8}>
          <Row align="middle" justify="end">
            {isDirty && <Title level={5} type="warning">EDITED</Title>}
            <Text type="secondary" className="ml-10 test-note-text"> Revision: 512</Text>
          </Row>
        </Col>
      </Row>
      <ReactQuill
        readOnly={readOnly}
        className={`${isDirty ? 'test-note-editor-dirty' : 'test-note-editor'} `}
        theme="snow"
        value={noteContent || ''}
        placeholder={placeholder}
        modules={{
          toolbar: [
            [{ size: [] }],
            ['bold', 'italic'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['blockquote'],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
        onChange={handleChangeNote}
      />
      <Row>
        {isMobile && (
        <Col span={4}>
          <FontAwesomeIcon
            onClick={goBack}
            icon={['fal', 'arrow-left']}
            style={{ fontSize: '1.5rem', color: 'gray', marginTop: '1rem', marginRight: '0.6rem' }}
          />
        </Col>
        )}
        <Col span={8}>
          <Row>
            <Text type="secondary">
              Last Revised
            </Text>
          </Row>
          <Row>
            <Text type="secondary">
              2021-20-12
            </Text>
            <Text className="ml-10">
              Smithers
            </Text>
          </Row>
        </Col>
        {isMobile ?
          (
            <Col span={12}>
              <Row justify="end" align="middle">
                <Button className="ml-10">EDIT</Button>
              </Row>
            </Col>
          ) :
          (
            <Col span={16}>
              <Row justify="end" align="middle">
                <Button>CANCEL</Button>
                <Button className="ml-10" type="primary">UPDATE</Button>
              </Row>
            </Col>
          )}

      </Row>
    </div>
  )
}

TestNote.propTypes = {
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  isMobile: PropTypes.bool,
  goBack: PropTypes.func,
}

TestNote.defaultProps = {
  placeholder: '',
  readOnly: false,
  isMobile: false,
  goBack: () => {},
}

export default TestNote
