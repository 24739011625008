import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'antd'
import { observer } from 'mobx-react'
import { useStores } from 'flynk.app.web.core.components/helpers/common'
import { useHistory } from 'react-router'
import { TestObjectType } from '../../../constants/test'

const TestsMenuItem = ({ id, name, selectedBgColor, itemType }) => {
  const SelectedBgClass = {
    blue: 'blue-bg',
    white: 'white-bg',
  }
  const {
    testStore: {
      getProductById,
      selectTestScenario,
      selectTestCase,
      selectedProductId,
      selectedTestCaseId,
      selectedTestScenarioId,
    },
  } = useStores()

  const history = useHistory()
  const onClick = () => {
    if (itemType === 0) {
      history.push(`/tests/${id}`)
      getProductById(id)
    } else {
      switch (itemType) {
        case TestObjectType.TestCase:
        {
          selectTestCase(id)
          break
        }
        case TestObjectType.TestScenario:
        {
          selectTestScenario(id)
          break
        }
        default:
        {
          // to do handle for suite
        }
      }
    }
  }

  const checkSelectedItem = () => {
    switch (itemType) {
      case TestObjectType.TestCase:
      {
        return id === selectedTestCaseId
      }
      case TestObjectType.TestScenario:
      {
        return id === selectedTestScenarioId
      }
      default:
      {
        return id === selectedProductId
      }
    }
  }
  const isSelected = checkSelectedItem()

  const containerCssClass = `tests-menu-item-container ${isSelected ? SelectedBgClass[selectedBgColor] : ''}`
  const contentCssClass = `tests-menu-item ${isSelected ? 'selected' : ''}`
  return (
    <>
      <Row onClick={onClick} className={containerCssClass}>
        <span className={contentCssClass}>{name}</span>
      </Row>
    </>
  )
}

TestsMenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  selectedBgColor: PropTypes.string.isRequired,
  itemType: PropTypes.number.isRequired,
}

export default observer(TestsMenuItem)
