import React from 'react'
import { Button, Row, Col } from 'antd'
import { useStores } from 'flynk.app.web.core.components/helpers/common'
import { BREAK_POINTS_NUMBERS } from 'flynk.app.web.core.components/constants'
import { TestInformation } from '../../components/Tests'
import { TestObjectType } from '../../constants/test'

const TestScenarioContainer = () => {
  const {
    testStore: {
      toggleTestStepDetailModal,
      selectedTestScenario,
      selectedTestScenarioDetail,
    },
  } = useStores()

  const onSelectTableRow = () => {
    const isIpad = window.innerWidth < BREAK_POINTS_NUMBERS.xl
    if (isIpad) {
      toggleTestStepDetailModal()
    }
  }

  return (
    <>
      <TestInformation onSelectRow={onSelectTableRow} className="test-scenario-wrapper" testObjectType={TestObjectType.TestScenario} data={selectedTestScenario} tableData={selectedTestScenarioDetail?.testStepVersions || []} />
      <Row justify="center">
        <Col>
          <Button className="test-page-action">DISCARD CHANGES</Button>
        </Col>
        <Col>
          <Button className="ml-10 test-page-action">Save</Button>
        </Col>
        <Col>
          <Button className="ml-10 test-page-action" type="primary">PUBLISH</Button>
        </Col>
      </Row>
    </>
  )
}

export default TestScenarioContainer
