import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import DocTitle from '../flynk.app.web.core.components/components/DocTitle'

const RouteWrapper = ({
  component: Component, docTitle, layout: Layout, path, ...rest
}) => (
  <Route
    path={path}
    {...rest}
    render={props => (
      <DocTitle title={docTitle}>
        <Layout>
          <Component {...props} />
        </Layout>
      </DocTitle>
    )}
  />)

RouteWrapper.propTypes = {
  component: PropTypes.elementType.isRequired,
  docTitle: PropTypes.string.isRequired,
  layout: MobxPropTypes.objectOrObservableObject.isRequired,
  path: PropTypes.string.isRequired,
}

export default withRouter(RouteWrapper)
