import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { createBrowserHistory } from 'history'

import ErrorsStore from 'flynk.app.web.core.data/stores/Errors/ErrorsStore'
import ProfileStore from 'flynk.app.web.core.data/stores/Profile/ProfileStore'
import RootAPI from '../api'

import ConfigStore from '../engyn.base.apps.web.shared/stores/ConfigStore'
import AuthStore from '../engyn.base.apps.web.shared/stores/AuthStore'

import TestStore from './TestStore'

const browserHistory = createBrowserHistory()
const routingStore = new RouterStore()

export const history = syncHistoryWithStore(browserHistory, routingStore)

const API_ENDPOINT = 'https://dev-engyn-qms.flynk.dev'

class RootStore {
  constructor() {
    const rootAPI = new RootAPI({ rootStore: this })

    this.testStore = new TestStore({ rootStore: this, rootAPI })
    this.authStore = new AuthStore({ rootStore: this, rootAPI })
    this.profileStore = new ProfileStore({ rootStore: this, rootAPI })
    this.configStore = new ConfigStore({ rootStore: this, rootAPI }, API_ENDPOINT)
    this.errorsStore = new ErrorsStore({ rootStore: this, rootAPI })
    this.routingStore = routingStore
  }
}

export default new RootStore()
