import React from 'react'
import { Provider, PropTypes as MobxPropTypes, observer } from 'mobx-react'
import { addUnPackToConsole } from './flynk.app.web.core.data/helpers/addunpack'

import MicroAppBase from './engyn.base.apps.web.shared/MicroAppBase'

import './App.less'
import Router from './router'
import rootStore from './stores'

addUnPackToConsole()
function App({ resource }) {
  return (
    <MicroAppBase resource={resource} rootStore={rootStore}>
      <Provider {...rootStore}>
        <Router />
      </Provider>
    </MicroAppBase>
  )
}

App.propTypes = {
  resource: MobxPropTypes.objectOrObservableObject.isRequired,
}

export default observer(App)
