import React from 'react'
import PropTypes from 'prop-types'
import { TestNote } from '../../components/Tests'

const TestNoteContainer = ({ isMobile, goBack }) => (
  <TestNote
    placeholder="This is markdown content. That describes the content of the test case step"
    readOnly={false}
    isMobile={isMobile}
    goBack={goBack}
  />
)

TestNoteContainer.propTypes = {
  isMobile: PropTypes.bool,
  goBack: PropTypes.func,
}

TestNoteContainer.defaultProps = {
  isMobile: false,
  goBack: () => {},
}

export default TestNoteContainer
