import React from 'react'
import { useStores } from 'flynk.app.web.core.components/helpers/common'
import { observer } from 'mobx-react'

import TestsProductMenu from './TestsProductMenu'
import TestsReleaseMenu from './TestsReleaseMenu'

const TestsProductReleaseMenu = () => {
  const {
    testStore: {
      products,
    },
  } = useStores()

  return (
    <>
      <TestsProductMenu products={products} />
      <TestsReleaseMenu />
    </>
  )
}

export default observer(TestsProductReleaseMenu)
