import { action, computed, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import get from 'lodash/get'
import set from 'lodash/set'
import find from 'lodash/find'
import {
  ALERT_TIMER,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION,
  LINK_TYPES,
  ORG_TYPES,
  ROLE_TYPES,
  SetDefaultPagination,
  USER_MODES,
  USER_STATUSES,
} from '../../constants'
import { isEmptyObject } from '../../helpers'

class ProfileStore {
  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @persist('object') @observable profile = {}
  @persist('object') @observable permissions = {}
  @persist('list') @observable platformOrganisations = []
  @persist('object') @observable platformOrganisationsPagination = DEFAULT_PAGINATION
  @persist('object') @observable selectedPlatformOrganisation = {}
  @persist('list') @observable internalOrganisations = []
  @observable updateProfileLoading = false
  @observable getOnboardUserLoading = false
  @observable resendValidationEmailLoading = false
  @observable resendValidationMobileLoading = false
  @observable platformOrganisationsLoading = false
  @observable platformOrganisationSearch = ''

  @computed get selectedPlatformOrganisationId() {
    return this.selectedPlatformOrganisation.id
  }

  @computed get organisations() {
    const orgs = this.profile.organisations || []

    return orgs.filter(org =>
      (org.orgType === ORG_TYPES.Internal || org.orgType > ORG_TYPES.Individual) &&
      org.roleId === this.currentRoleId)
  }

  @computed get permission() {
    return get(this.permissions, this.userId, {})
  }

  @computed
  get currentRole() {
    const { roles } = this.profile
    const currentRoleId = get(this.permissions, `${this.userId}.roles.current`, '')
    if (roles?.length > 0) {
      const currentRole = currentRoleId ? find(roles, { id: currentRoleId }) : roles[0]
      if (currentRole) {
        return currentRole
      }
    }

    return {}
  }

  @computed get currentRoleId() {
    return this.currentRole.id || ''
  }

  @computed get user() {
    return get(this.profile, 'user', {})
  }

  @computed get userId() {
    return get(this.profile, 'user.id', '')
  }

  @computed get personId() {
    return get(this.profile, 'user.personId', '')
  }

  @computed get currentRoleOrganisationId() {
    return get(this.currentRole, 'orgId', '')
  }

  @computed get currentRoleOrganisation() {
    return get(this.currentRole, 'organisation', {})
  }

  @computed get currentOrganisationId() {
    return this.selectedPlatformOrganisationId || this.currentRoleOrganisationId
  }

  @computed get currentOrganisation() {
    return this.selectedPlatformOrganisation || this.currentRoleOrganisation
  }

  @computed get currentMode() {
    return get(this.permission, 'modes.current', '')
  }

  @computed get orgType() {
    return get(this.currentRole, 'organisation.orgType', '')
  }

  @computed get roleType() {
    return get(this.currentRole, 'type', ROLE_TYPES.Undefined)
  }

  @computed get isValidUser() {
    return (this.currentMode >= USER_MODES.SupportManager) || (
      Number.isInteger(this.orgType) && this.orgType >= ORG_TYPES.Individual
    )
  }

  @computed get isInternalRole() {
    return this.currentRole.organisation.orgType === ORG_TYPES.Internal
  }

  // Individual
  @computed
  get isIndividual() {
    return Number.isInteger(this.orgType) && this.orgType === ORG_TYPES.Individual
  }

  // Org Member
  @computed
  get isOrgMember() {
    return this.roleType === ROLE_TYPES.Normal &&
      Number.isInteger(this.orgType) && this.orgType > ORG_TYPES.Individual
  }

  // Application Admin
  @computed
  get isOrgAdmin() {
    return this.roleType === ROLE_TYPES.Admin &&
      Number.isInteger(this.orgType) && this.orgType > ORG_TYPES.Individual
  }

  @computed
  get isPartner() {
    return this.currentMode === USER_MODES.Partner
  }

  @computed
  get isInternalUser() {
    return [
      USER_MODES.SysAdmin,
      USER_MODES.TechSupport,
      USER_MODES.DevSupport,
      USER_MODES.Admin,
      USER_MODES.Support,
      USER_MODES.SupportManager,
    ].includes(this.currentMode)
  }

  // Internal User
  @computed
  get isSupportManager() {
    return this.currentMode === USER_MODES.SupportManager
  }

  @computed
  get isSupport() {
    return this.currentMode === USER_MODES.Support
  }

  @computed
  get isAdmin() {
    return this.currentMode === USER_MODES.Admin
  }

  @computed
  get isTechSupport() {
    return this.currentMode === USER_MODES.TechSupport
  }

  @computed
  get isDevSupport() {
    return this.currentMode === USER_MODES.DevSupport
  }

  @computed
  get isSysAdmin() {
    return this.currentMode === USER_MODES.SysAdmin
  }

  @computed
  get isSysAdminRole() {
    return this.currentOrganisation.orgType === ORG_TYPES.Internal &&
      this.currentRole.type === ROLE_TYPES.Admin
  }

  @computed
  get isInternalOrganisation() {
    const { profileStore } = this.rootStore

    return profileStore.currentOrganisation.orgType === ORG_TYPES.Internal
  }

  @computed
  get hasMorePlatformOrganisations() {
    return !this.platformOrganisationsPagination.total ||
      this.platformOrganisationsPagination.total > this.platformOrganisations.length
  }

  @action.bound
  async getContext() {
    try {
      const res = await this.rootAPI.authAPI.getContext()

      const {
        preferredMode, preferredRoleId, roles, user,
      } = res.payload

      const info = await this.getRolesAndOrganisations(roles)
      if (info.roles && info.organisations) {
        this.profile = {
          ...res.payload,
          ...info,
          avatar: get(user, 'avatarSet.thumbnails[0].url', ''),
        }

        this.setupPermission(preferredRoleId, preferredMode)
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  setPermission(...args) {
    if (this.userId) {
      const permissions = { ...this.permissions }

      if (args && args.length) {
        args.forEach(({ key, value }) => {
          set(permissions, `${this.userId}.${key}`, value)
        })

        this.permissions = permissions
      }
    }
  }

  setCurrentOrganisation(organisation) {
    const { roles } = this.profile
    const currentRoleId = get(this.permissions, `${this.userId}.roles.current`, '')

    if (roles && roles.length > 0) {
      const currentRole = currentRoleId ? find(roles, { id: currentRoleId }) : roles[0]

      if (currentRole) currentRole.organisation = organisation
    }
  }

  @action.bound
  async uploadAvatar(data) {
    const res = await this.rootAPI.profileAPI.getAvatarPresignedUrl(this.userId, data)

    if (res && res.payload) {
      const { file, contentType } = data

      await this.rootAPI.profileAPI.uploadAvatar(res.payload.signedUrl, file, contentType)
    }
  }

  @action.bound
  async updateProfile(data) {
    this.updateProfileLoading = true
    try {
      const profile = { ...this.profile }

      if (data.avatar.objectName && data.avatar.file) {
        await this.uploadAvatar(data.avatar)
        if (data.croppedAvatar) {
          profile.avatar = data.croppedAvatar
        }
      }

      if (data.user) {
        profile.user = {
          ...profile.user,
          ...data.user,
        }
        await this.rootAPI.profileAPI.updateProfile(profile)

        this.profile = profile
        this.rootStore.alertStore.success({
          title: 'The profile has been updated successfully',
          timer: ALERT_TIMER,
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateProfileLoading = false
    }
  }

  @action.bound
  reset() {
    this.profile = {}
    this.selectedPlatformOrganisation = {}
    this.platformOrganisations = []
    this.platformOrganisationsPagination = {}
    this.platformOrganisationSearch = ''
  }

  @action.bound
  setPlatformOrganisationSearch(value) {
    this.platformOrganisationSearch = value
  }

  @action.bound
  async getInternalOrganisations() {
    const { organisationAPI } = this.rootAPI
    const { errorsStore } = this.rootStore
    this.platformOrganisationsLoading = true
    try {
      const res = await organisationAPI.getOrganisations({
        orgType: ORG_TYPES.Internal,
        skip: 0,
        take: 20,
      })

      this.internalOrganisations = res.payload.items
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.platformOrganisationsLoading = false
    }
  }

  @action.bound
  async getPlatformOrganisations(page = 1) {
    const { organisationAPI } = this.rootAPI
    const { errorsStore } = this.rootStore

    if (this.platformOrganisationsLoading) return

    this.platformOrganisationsLoading = true

    try {
      const { pageSize } = this.platformOrganisationsPagination

      const res = await organisationAPI.getOrganisations({
        skip: pageSize ? (page - 1) * pageSize : 0,
        take: pageSize || DEFAULT_PAGE_SIZE,
        name: this.platformOrganisationSearch,
      })

      this.platformOrganisations = res.payload.items
      this.platformOrganisationsPagination = SetDefaultPagination(
        this.platformOrganisationsPagination, res,
      )

      if (isEmptyObject(this.selectedPlatformOrganisation)) {
        this.selectPlatformOrganisation(this.profile.organisations[0])
      }
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.platformOrganisationsLoading = false
    }
  }

  @action.bound
  async loadMorePlatformOrganisations() {
    const { organisationAPI } = this.rootAPI
    const { errorsStore } = this.rootStore

    if (this.platformOrganisationsLoading) return

    this.platformOrganisationsLoading = true

    try {
      const { current, pageSize } = this.platformOrganisationsPagination

      const res = await organisationAPI.getOrganisations({
        skip: current && pageSize ? current * pageSize : 0,
        take: pageSize || DEFAULT_PAGE_SIZE,
        name: this.platformOrganisationSearch,
      })

      this.platformOrganisations = [
        ...this.platformOrganisations,
        ...res.payload.items,
      ]
      this.platformOrganisationsPagination = SetDefaultPagination(
        this.platformOrganisationsPagination, res,
      )
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.platformOrganisationsLoading = false
    }
  }

  @action.bound
  async getOnboardUser(isRechecked) {
    if (isRechecked) {
      this.getOnboardUserLoading = true
    }

    const userStatus = get(this.profile, 'user.status', 0)
    if (this.userId && userStatus < USER_STATUSES.Enabled) {
      let email = this.profile.email ? { ...this.profile.email } : {}
      let mobile = this.profile.mobile ? { ...this.profile.mobile } : {}
      const user = this.profile.user ? { ...this.profile.user } : {}

      try {
        const res = await this.rootAPI.userAPI.getOnboardUser(this.userId)
        const invalidatedLinks = get(res, 'payload.invalidatedLinks', [])
        if (invalidatedLinks.length) {
          const invalidatedEmail = invalidatedLinks.find(item => item.type === LINK_TYPES.Email)
          const invalidatedMobile = invalidatedLinks.find(item => item.type === LINK_TYPES.Mobile)

          email = invalidatedEmail || { ...email, isValidated: true }
          mobile = invalidatedMobile || { ...email, isValidated: true }
        } else {
          email.isValidated = true
          mobile.isValidated = true
          user.status =
            userStatus > USER_STATUSES.Enabled ? userStatus : USER_STATUSES.Enabled
        }

        runInAction(() => {
          this.profile = {
            ...this.profile,
            email,
            mobile,
            user,
          }
        })
      } catch (err) {
        this.rootStore.errorsStore.addError(err)
      } finally {
        if (isRechecked) {
          this.getOnboardUserLoading = false
        }
      }
    }
  }

  @action.bound
  async resendValidation({ linkId, type }) {
    if (type === LINK_TYPES.Email) {
      this.resendValidationEmailLoading = true
    } else if (type === LINK_TYPES.Mobile) {
      this.resendValidationMobileLoading = true
    }

    try {
      await this.rootAPI.userAPI.resendValidation(linkId)
      if (type === LINK_TYPES.Email) {
        this.rootStore.alertStore.success({
          title: 'The email validation has been resent successfully',
          timer: ALERT_TIMER,
        })
      } else if (type === LINK_TYPES.Mobile) {
        this.rootStore.alertStore.success({
          title: 'The mobile number validation has been resent successfully',
          timer: ALERT_TIMER,
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      if (type === LINK_TYPES.Email) {
        this.resendValidationEmailLoading = false
      } else if (type === LINK_TYPES.Mobile) {
        this.resendValidationMobileLoading = false
      }
    }
  }

  @action.bound
  isAccessDenied(mode) {
    const currentMode = get(this.permission, 'modes.current', '')
    if (currentMode) {
      if (currentMode === USER_MODES.NoneUser) {
        return mode > USER_MODES.Individual
      }

      return mode > currentMode
    }

    return false
  }

  @action.bound
  setupPermission(preferredRoleId, preferredMode) {
    this.setPermission({ key: 'roles.preferred', value: preferredRoleId }, { key: 'modes.preferred', value: preferredMode })

    const permission = get(this.permissions, this.userId)
    const currentRoleId = get(this.permissions, `${this.userId}.roles.current`)
    const currentMode = get(this.permissions, `${this.userId}.mode.current`)

    if (this.userId && (!permission || (permission && !currentRoleId))) {
      this.setPermission({ key: 'roles.current', value: preferredRoleId })
    }

    if (this.userId && (!permission || (permission && !currentMode))) {
      this.setPermission({ key: 'modes.current', value: preferredMode })
    }
  }

  @action.bound
  async getRolesAndOrganisations(roles) {
    if (roles && roles.length) {
      const organisations = []
      const rolePromises = roles.map(async (role) => {
        const r = { ...role }
        const res = await this.rootAPI.organisationAPI.getOrganisationById(role.orgId, role.id)
        const organisation = res.payload
        r.organisation = organisation

        organisations.push({
          ...organisation,
          roleId: role.id,
        })

        return r
      })

      try {
        const rs = await Promise.all(rolePromises)
        return {
          roles: rs,
          organisations,
        }
      } catch (err) {
        this.rootStore.errorsStore.addError(err)
        return {
          roles: null,
          organisation: null,
        }
      }
    }

    return {}
  }

  @action.bound
  getSearchOrganisationAjax() {
    const { configStore, authStore } = this.rootStore
    const { userAPI } = this.rootAPI
    const url = `${configStore.apiEndpoint}/${configStore.version}/Organisations`
    const headers = userAPI.request.getHeaders()
    const { pageSize } = DEFAULT_PAGINATION

    return {
      url,
      headers,
      delay: 250,
      cache: true,
      data: (params) => {
        const page = params.page || 1
        const skip = (page - 1) * pageSize
        if (params.term) {
          return {
            name: params.term,
            take: pageSize,
            skip,
            orgType: 20,
          }
        }

        return {
          take: pageSize,
          skip,
          orgType: 20,
        }
      },
      error: (jqXHR) => {
        if (jqXHR && jqXHR.status === 401) {
          return authStore.logout()
        }
        return { results: [] }
      },
      processResults: (data) => {
        const payload = get(data, 'payload', {})
        const hasGlobal = get(payload, 'page', 0) <= 1
        let results = { results: [] }
        const items = get(payload, 'items', [])
        const totalItems = get(payload, 'totalItems', [])
        const currentPage = get(payload, 'currentPage', [])
        const total = hasGlobal ? totalItems + 1 : totalItems

        if (items.length > 0) {
          let orgs = items.map(item => ({
            id: item.id,
            text: item.displayName,
          }))
          if (hasGlobal && currentPage === 1) {
            const userOrgs = this.profile.organisations.map(item => ({
              id: item.id,
              text: item.displayName,
            }))

            orgs = userOrgs.concat(orgs)
          }

          results = {
            results: orgs,
            pagination: {
              more: orgs.length < total,
            },
          }
        }

        return results
      },
    }
  }

  @action.bound
  async selectPlatformOrganisation(organisation) {
    if (organisation.id !== this.selectedPlatformOrganisation.id) {
      const org =
        this.profile.organisations.find(o => o.id === organisation.id) ||
        organisation

      if (org.orgType !== this.selectedPlatformOrganisation.orgType) {
        this.rootStore.routingStore.push('/dashboard')
      }

      this.selectedPlatformOrganisation = org
    }
  }
}

export default ProfileStore
