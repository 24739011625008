import React from 'react'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import PropTypes from 'prop-types'
import { Space } from 'antd'

import TestsMenuItem from './TestsMenuItem'

const TestsMenuItems = ({ items, selectedBgColor, className, itemType }) => (
  <Space className={className} direction="vertical" size={5} style={{ width: '100%' }}>
    {
      items?.map(
        ({ id, name }) => (
          <TestsMenuItem
            id={id}
            key={id}
            name={name}
            selectedBgColor={selectedBgColor}
            itemType={itemType}
          />
        ),
      )
    }
  </Space>
)

TestsMenuItems.propTypes = {
  items: MobxPropTypes.arrayOrObservableArray.isRequired,
  selectedBgColor: PropTypes.string.isRequired,
  className: PropTypes.string,
  itemType: PropTypes.number,
}

TestsMenuItems.defaultProps = {
  className: '',
  itemType: 0,
}

export default TestsMenuItems
