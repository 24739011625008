import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'

const router = () => (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
)

export default router
