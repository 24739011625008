import React, { useEffect } from 'react'
import { PropTypes as MobxPropTypes, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Loading from 'flynk.app.web.core.components/components/Loading'

function App({ resource, rootStore, children }) {
  useEffect(() => {
    if (!rootStore.authStore.isLoadedUserContext) {
      const { auth } = resource
      rootStore.authStore.setAuth(auth)
    }
  })

  return (
    rootStore.authStore.isLoadedUserContext ?
      <>
        {children}
      </> :
      <Loading />
  )
}

App.propTypes = {
  resource: MobxPropTypes.objectOrObservableObject.isRequired,
  rootStore: PropTypes.shape({
    authStore: PropTypes.shape({
      isLoadedUserContext: PropTypes.bool.isRequired,
      setAuth: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  children: PropTypes.element.isRequired,
}

export default observer(App)
