import React, { Component } from 'react'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { BREAK_POINTS_NUMBERS } from 'flynk.app.web.core.components/constants'
import { observer, inject } from 'mobx-react'
import { TestNote } from '../components/Tests'
import { TestScenarioContainer, TestCaseContainer } from '../containers/Tests'
import { TabletTestsContainer } from '../containers/Tests/TabletUI'
import { TestCategory } from '../constants/test'

@inject(stores => ({
  getSoftwareProducts: stores.testStore.getSoftwareProducts,
  testCategoryMode: stores.testStore.testCategoryMode,
}))
class TestPage extends Component {
static propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  getSoftwareProducts: PropTypes.func.isRequired,
  testCategoryMode: PropTypes.number.isRequired,
}

state = {
  isMobile: window.innerWidth < BREAK_POINTS_NUMBERS.sm,
  isTablet: window.innerWidth < BREAK_POINTS_NUMBERS.xl,
}

componentDidMount() {
  const { getSoftwareProducts } = this.props
  const {
    match: { params: { id } },
  } = this.props
  getSoftwareProducts(id)
  this.redirectIfOnMobile()
  window.addEventListener('resize', this.handleResize)
}

componentDidUpdate() {
  this.redirectIfOnMobile()
}

componentWillUnmount() {
  window.removeEventListener('resize', this.handleResize)
}

handleResize = () => {
  const isMobile = window.innerWidth < BREAK_POINTS_NUMBERS.sm
  const isTablet = window.innerWidth < BREAK_POINTS_NUMBERS.xl
  if (isMobile !== this.state.isMobile || isTablet !== this.state.isTablet) {
    this.setState({ isMobile, isTablet })
  }
}

redirectIfOnMobile = () => {
  const {
    history: { push },
    match: { params: { id } },
  } = this.props
  if (this.state.isMobile) {
    push(`/tests/${id}/testcase`)
  }
}

render() {
  const { testCategoryMode } = this.props
  return (
    this.state.isTablet ? (
      <TabletTestsContainer />
    ) :
      (
        <Row>
          <Col span={12} className="section-wrapper-test-case">
            {testCategoryMode === TestCategory.CASE ?
              <TestCaseContainer /> :
              <TestScenarioContainer /> }
          </Col>
          <Col span={12} className="section-wrapper">
            <TestNote
              placeholder="This is markdown content. That describes the content of the test case step"
              readOnly={false}
            />
          </Col>
        </Row>
      )
  )
}
}

export default observer(TestPage)
