import React, { useState, useEffect } from 'react'
import { Row, Col, Typography } from 'antd'

import { observer, PropTypes as MobxPropTypes } from 'mobx-react'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

import { DragDropCustomComponent } from 'flynk.app.web.core.components/components/DragAndDrop/DragDropCustomComponent'
import { toJS } from 'mobx'
import { TestObjectType } from '../../constants/test'
import TableDNDRow from '../SimpleElements/TableDNDRow'
import TestSectionHeader from './TestSectionHeader'
import { reorder } from '../../helpers'

const { Text, Title } = Typography

const TestInformation = ({ testObjectType,
  data, tableData, className, isMobile, onSelectRow }) => {
  const [items, setItems] = useState(tableData)
  console.log('dung', toJS(data))
  console.log('dung', toJS(tableData))
  const [isDirty, setIsDirty] = useState(false)
  useEffect(() => {
    setItems(tableData)
  }, [tableData])

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    )

    const preferredPerformerIds = newItems.map(item => item.id)
    const oldPerformerIds = items.map(performer => performer.id)

    if (!isEqual(preferredPerformerIds, oldPerformerIds)) {
      setItems(newItems)
    }
  }

  const renderTableHeader = () => (
    <Row justify="center" align="middle" className="drag-and-drop-row">
      <Col offset={10} span={isMobile ? 9 : 5}>
        Last Revised
      </Col>
      <Col span={isMobile ? 0 : 5}>
        By
      </Col>
      <Col span={isMobile ? 5 : 4}>
        Revision
      </Col>
    </Row>
  )

  const onChangeTestData = () => {
    setIsDirty(true)
  }

  const renderDNDItem = item => (
    <TableDNDRow
      onClick={onSelectRow}
      isMobile={isMobile}
      onChange={onChangeTestData}
      item={item}
      readOnly
    />
  )

  return (
    <div className={className}>
      <TestSectionHeader
        data={data}
        title={testObjectType === TestObjectType.TestCase ? 'TEST STEP:' : 'TEST SCENARIO:'}
        isDirty={isDirty}
        isSimpleHeader={isMobile}
      />
      <div className="drag-and-drop-table">
        <Row align="middle">
          <Title level={5}>{ testObjectType === TestObjectType.TestCase ? 'TEST STEPS' : 'SCENARIO CASES' }</Title>
        </Row>
        <Row>
          <Text type="secondary mb-5">
            { testObjectType === TestObjectType.TestCase ? ' Steps that should be performed in this Case' : 'Cases that should be covered in this Scenario' }
          </Text>
        </Row>
        <div className={isMobile ? 'table-mobile-wrapper' : ''}>
          {renderTableHeader()}
          { items?.length > 0 &&
          <DragDropCustomComponent
            onDragEnd={onDragEnd}
            items={items}
            renderItem={renderDNDItem}
          />}
        </div>
      </div>
    </div>
  )
}

TestInformation.propTypes = {
  testObjectType: PropTypes.number.isRequired,
  data: MobxPropTypes.objectOrObservableObject.isRequired,
  tableData: MobxPropTypes.arrayOrObservableArray,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  onSelectRow: PropTypes.func,
}

TestInformation.defaultProps = {
  className: '',
  isMobile: false,
  onSelectRow: () => {},
  tableData: [],
}

export default observer(TestInformation)
