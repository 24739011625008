import React from 'react'
import TestsMenuTab from '../../components/Tests/TestsCompositionCaseMenu/TestsMenuTab'

const ThirdNavBarContainer = () => (
  <div className="p-20">
    <TestsMenuTab />
  </div>
)

export default ThirdNavBarContainer
