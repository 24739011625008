import React from 'react'
import { Button, Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useStores } from 'flynk.app.web.core.components/helpers/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TestInformation } from '../../../components/Tests'
import { TestObjectType } from '../../../constants/test'

const MobileTestScenarioContainer = ({ history, match }) => {
  const { goBack, push } = history

  const {
    testStore: {
      selectedTestScenario,
      selectedTestScenarioDetail,
    },
  } = useStores()

  const onSelectTableRow = () => {
    const { params: { id } } = match
    push(`/tests/${id}/teststep`)
  }

  return (
    <div className="section-wrapper-test-case">
      <TestInformation onSelectRow={onSelectTableRow} isMobile className="test-scenario-wrapper" testObjectType={TestObjectType.TestScenario} data={selectedTestScenario} tableData={selectedTestScenarioDetail?.testStepVersions || []} />
      <Row className="mobile-button-group" justify="center" align="middle">
        <Col>
          <FontAwesomeIcon
            onClick={goBack}
            icon={['fal', 'arrow-left']}
            style={{ fontSize: '1.5rem', color: 'gray', marginTop: '1rem', marginRight: '0.6rem' }}
          />
        </Col>
        <Col>
          <Button className="ml-10 test-page-action">DUPLICATE</Button>
        </Col>
        <Col>
          <Button className="ml-10 test-page-action" type="primary">EDIT</Button>
        </Col>
      </Row>
    </div>
  )
}

MobileTestScenarioContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default observer(MobileTestScenarioContainer)
