import React from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'

const { Option } = Select
const SimpleDropDown = ({ data, defaultValue, style, options }) => {
  let selectionOptions = options
  if (options === null) {
    selectionOptions = data?.map(el => (
      <Option value={el} key={el}>
        {el}
      </Option>
    ))
  }
  return (
    <Select defaultValue={defaultValue} style={style}>
      { selectionOptions }
    </Select>
  )
}

SimpleDropDown.propTypes = {
  data: MobxPropTypes.arrayOrObservableArray.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    MobxPropTypes.objectOrObservableObject,
  ]),
  style: MobxPropTypes.objectOrObservableObject,
  options: PropTypes.element,
}

SimpleDropDown.defaultProps = {
  defaultValue: null,
  style: {},
  options: null,
}

export default SimpleDropDown
