import React from 'react'
import { PropTypes as MobxPropTypes, observer } from 'mobx-react'
import { Select } from 'antd'
import { useStores } from 'flynk.app.web.core.components/helpers/common'
import { useHistory } from 'react-router'

import TestsMenuHeader from '../../TestsMenuItem/TestsMenuHeader'

const { Option } = Select

const TestsProductMenu = ({ products }) => {
  const history = useHistory()
  const {
    testStore: {
      getProductById,
      selectedProductId,
    },
  } = useStores()

  const onChangeProduct = (id) => {
    history.push(`/tests/${id}`)
    getProductById(id)
  }

  return (
    <div className="tests-product-menu-ipad">
      <TestsMenuHeader name="PRODUCT" bottomLineColor="blue" />
      <Select defaultValue={selectedProductId} value={selectedProductId} style={{ width: '100%' }} onChange={onChangeProduct}>
        {
        products.map(
          product => (
            <Option key={product.id} value={product.id}>{product.name}</Option>
          ),
        )
      }
      </Select>
    </div>
  )
}

TestsProductMenu.propTypes = {
  products: MobxPropTypes.arrayOrObservableArray.isRequired,
}

export default observer(TestsProductMenu)
