import React from 'react'
import { Row, Col, Modal, Button, Typography } from 'antd'
import { useStores } from 'flynk.app.web.core.components/helpers/common'
import TestNote from './TestNote'
import TestSectionHeader from './TestSectionHeader'
// import TestsMenuItems from './TestsMenuItem/TestsMenuItems'

const { Title } = Typography

const TestStepDetailModal = () => {
  const {
    testStore: {
      toggleTestStepDetailModal,
    },
  } = useStores()

  const onCloseModal = () => {
    toggleTestStepDetailModal()
  }

  const suiteNavs = [
    {
      key: '1',
      path: '/tests',
      text: 'Test Step 123',
      isSelected: true,
    },
    {
      key: '2',
      path: '/tests',
      text: 'Test Step 124',
      isSelected: false,
    },
    {
      key: '3',
      path: '/tests',
      text: 'Test Step 125',
      isSelected: false,
    },
    {
      key: '4',
      path: '/tests',
      text: 'Test Step 126',
      isSelected: false,
    },
    {
      key: '5',
      path: '/tests',
      text: 'Test Step 127',
      isSelected: false,
    },
  ]

  return (
    <Modal className="test-detail-modal" visible width={700}>
      <Row>
        <Col span={8} className="test-step-menu-wrapper">
          <TestSectionHeader isSimpleHeader title="TEST CASE" data={{ version: 'v2.12.1', name: 'Case 1123' }} />
          <Title level={5} className="mb-10" type="secondary">STEPS</Title>
          <div className="pl-20 test-detail-modal-test-menu" items={suiteNavs} selectedBgColor="white" />
          <Row className="test-detail-modal-button" justify="center">
            <Button className="mt-40" onClick={onCloseModal}>Close</Button>
          </Row>
        </Col>
        <Col span={16} className="section-wrapper">
          <TestNote
            placeholder="This is markdown content. That describes the content of the test case step"
            readOnly={false}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default TestStepDetailModal
