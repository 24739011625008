import { action, observable, runInAction, toJS, computed } from 'mobx'
import { persist } from 'mobx-persist'
import { last } from 'lodash'
import resettableMixin from '../flynk.app.web.core.data/stores/resettableMixin'

import { TestCategory } from '../constants/test'

@resettableMixin
export default class TestStore {
  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @computed get selectedProductId() {
    return this.selectedProduct?.id || ''
  }

  @computed get selectedTestCaseId() {
    return this.selectedTestCase?.id || ''
  }

  @computed get selectedTestScenarioId() {
    return this.selectedTestScenario?.id || ''
  }

  // modal visible declaration
  @observable testStepDetailModalOpened = false

  // action
  @action.bound
  toggleTestStepDetailModal() {
    this.testStepDetailModalOpened = !this.testStepDetailModalOpened
  }

  @observable isShowAddScenarioFormModal = false
  @action.bound
  toggleAddScenarioFormModal() {
    this.isShowAddScenarioFormModal = !this.isShowAddScenarioFormModal
  }

  @observable isShowAddSuiteFormModal = false
  @action.bound
  toggleAddSuiteFormModal() {
    this.isShowAddSuiteFormModal = !this.isShowAddSuiteFormModal
  }

  @observable isShowAddCaseFormModal = false
  @action.bound
  toggleAddCaseFormModal() {
    this.isShowAddCaseFormModal = !this.isShowAddCaseFormModal
  }

  @persist('list') @observable products = []
  @persist('object') @observable selectedProduct = {}

  @persist('list') @observable draftReleases = [
    {
      id: 'draft-engyn-web-app',
      path: '/tests',
      name: 'Engyn WebApp v1.2.3',
    },
    {
      id: 'draft-engyn-base-server',
      path: '/tests',
      name: 'Engyn Base Server v1.3.5',
    },
  ]

  @persist('list') @observable plannedReleases = [
    {
      id: 'planned-engyn-web-app',
      path: '/tests',
      name: 'Engyn WebApp v1.2.3',
    },
    {
      id: 'planned-engyn-base-server',
      path: '/tests',
      name: 'Engyn Base Server v1.3.5',
    },
  ]

  @persist('list') @observable recentlyReleases = [
    {
      id: 'recent-engyn-ios',
      path: '/tests',
      name: 'Engyn iOS v1.2.3',
    },
    {
      id: 'recent-engyn-qms',
      path: '/tests',
      name: 'Engyn QMS Server v1.3.5',
    },
    {
      id: 'recent-engyn-web-app',
      path: '/tests',
      name: 'Engyn WebApp v1.2.2',
    },
  ]

  @persist('list') @observable scenarios = []

  @persist('list') @observable suites = []

  @persist('list') @observable cases = []

@action.bound
  async getSoftwareProducts(selectedProductId) {
    try {
      const res = await this.rootAPI.testAPI.getSoftwareProductsByOrganizationsId('d66bef94-a562-4b39-b959-a3ad10ec7765')
      const { payload } = res
      if (payload) {
        this.products = payload
        if (payload.length > 0) {
          if (selectedProductId) {
            this.getProductById(selectedProductId)
          } else {
            const currentId = this.selectedProductId.length > 0 ?
              this.selectedProductId : payload[0].id
            this.getProductById(currentId)
          }
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @observable onLoadingTestData = false
  @action.bound
  async getProductById(id) {
    try {
      this.onLoadingTestData = true
      this.selectedProduct = this.products.find(p => p.id === id)
      const promises = [
        this.rootAPI.testAPI.getSoftwareProductsTestSuitesById(id),
        this.rootAPI.testAPI.getSoftwareProductsTestCasesById(id),
        this.rootAPI.testAPI.getSoftwareProductsTestScenariosById(id),
      ]

      const [suitesRes, testCaseRes, testScenarioRes] = await Promise.all(promises)
      runInAction(() => {
        this.suites = suitesRes?.payload
        this.cases = testCaseRes?.payload
        this.scenarios = testScenarioRes?.payload
        this.switchTestCategoryMode(TestCategory.COMPOSITION)
      })
      await this.selectTestScenario()
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.onLoadingTestData = false
    }
  }

  @persist('object') @observable selectedTestScenario = {}
  @persist('object') @observable selectedTestScenarioDetail = {}

  @action.bound
  async selectTestScenario(id) {
    try {
      if (id) {
        this.selectedTestScenario = this.scenarios.find(x => x.id === id) || {}
      } else {
        [this.selectedTestScenario] = this.scenarios
      }
      console.log(toJS(this.selectedTestScenario))
      if (this.selectedTestScenario) {
        const lastVersionId = last(this.selectedTestScenario.versions)?.id
        if (lastVersionId) {
          this.getTestScenariosByVersionId(lastVersionId)
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async getTestScenariosByVersionId(versionId) {
    try {
      this.onLoadingTestData = true
      const res = await this.rootAPI.testAPI.getTestScenariosByVersionId(versionId)
      const { payload } = res
      if (payload) {
        this.selectedTestScenarioDetail = payload
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.onLoadingTestData = false
    }
  }

  @persist('object') @observable selectedTestCase = {}
  @persist('object') @observable selectedTestCaseDetail = {}

  @action.bound
  async selectTestCase(id) {
    try {
      if (id) {
        this.selectedTestCase = this.cases.find(x => x.id === id) || {}
      } else {
        [this.selectedTestCase] = this.cases
      }
      if (this.selectedTestCase) {
        const lastVersionId = last(this.selectedTestCase.versions)?.id
        if (lastVersionId) {
          this.getTestCasesByVersionId(lastVersionId)
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async getTestCasesByVersionId(versionId) {
    try {
      this.onLoadingTestData = true
      const res = await this.rootAPI.testAPI.getTestCasesByVersionId(versionId)
      const { payload } = res
      if (payload) {
        this.selectedTestCaseDetail = payload
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.onLoadingTestData = false
    }
  }

  @observable testCategoryMode = TestCategory.COMPOSITION
  @action.bound
  switchTestCategoryMode(mode) {
    const selectedMode = +mode
    if (this.testCategoryMode !== selectedMode) {
      this.testCategoryMode = selectedMode
      if (selectedMode === TestCategory.COMPOSITION) {
        this.selectTestScenario()
      } else {
        this.selectTestCase()
      }
    }
  }
}
