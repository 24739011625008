import React from 'react'

import { useStores } from 'flynk.app.web.core.components/helpers/common'

import { observer } from 'mobx-react'
import TestsMenuHeader from '../TestsMenuItem/TestsMenuHeader'
import TestsMenuItems from '../TestsMenuItem/TestsMenuItems'
import TestsMenuSearch from '../TestsMenuItem/TestsMenuSearch'
import { TestObjectType } from '../../../constants/test'

const TestsScenarioMenu = () => {
  const {
    testStore: {
      scenarios,
    },
  } = useStores()

  return (
    <>
      <TestsMenuHeader formName="scenario" name="Scenarios" bottomLineColor="white" canAddNewItem />
      <TestsMenuSearch />
      <TestsMenuItems itemType={TestObjectType.TestScenario} items={scenarios} selectedBgColor="white" />
    </>
  )
}

export default observer(TestsScenarioMenu)
