import React from 'react'

import { useStores } from 'flynk.app.web.core.components/helpers/common'

import { observer } from 'mobx-react'
import TestsMenuHeader from '../TestsMenuItem/TestsMenuHeader'
import TestsMenuItems from '../TestsMenuItem/TestsMenuItems'
import TestsMenuSearch from '../TestsMenuItem/TestsMenuSearch'
import { TestObjectType } from '../../../constants/test'

const TestsSuiteMenu = () => {
  const {
    testStore: {
      suites,
    },
  } = useStores()

  return (
    <div className="tests-suite-menu">
      <TestsMenuHeader formName="suite" name="Suites" bottomLineColor="white" canAddNewItem />
      <TestsMenuSearch />
      <TestsMenuItems itemType={TestObjectType.TestScenario} items={suites} selectedBgColor="white" />
    </div>
  )
}

export default observer(TestsSuiteMenu)
