import React from 'react'
import TestsProductReleaseMenu from '../../components/Tests/TestsProductReleaseMenu/TestsProductReleaseMenu'

const SecondaryNavBarContainer = () => (
  <div style={{ padding: '20px' }}>
    <TestsProductReleaseMenu />
  </div>
)

export default SecondaryNavBarContainer
