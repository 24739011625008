import React from 'react'
import PropTypes from 'prop-types'
import LogoDeviceWhitePng from '../../../engyn.base.apps.web.shared/images/logos/logo-device-white.png'

const TestsMenuFormModalHeader = ({ name }) => (
  <div className="tests-form-modal-header">
    <div className="tests-form-modal-header-logo">
      <img alt="logo-device-white" src={LogoDeviceWhitePng} />
    </div>
    <div className="tests-form-modal-header-text-container">
      <span>{name}</span>
    </div>
    <div className="tests-form-modal-header-bottom-line" />
  </div>
)

TestsMenuFormModalHeader.propTypes = {
  name: PropTypes.string.isRequired,
}

export default TestsMenuFormModalHeader
