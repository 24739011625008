import React from 'react'
import PropTypes from 'prop-types'

const TestsMenuHeaderLv2 = ({ name }) => (
  <>
    <span className="tests-menu-header-lv2">{name}</span>
  </>
)

TestsMenuHeaderLv2.propTypes = {
  name: PropTypes.string.isRequired,
}

export default TestsMenuHeaderLv2
