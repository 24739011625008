import axios from 'axios'
import FileSaver from 'file-saver'

axios.interceptors.response.use(response => response.data, error => Promise.reject(error.response))

export const serializeQueryList = (paramName, array) => {
  if (array) {
    return array
      .reduce((acc, value, index) => `${acc}${paramName}[${index}]=${value}&`, '')
      .slice(0, -1) // removes the edge "&"
  }
  return ''
}

export const serializeFilterList = (paramName, array) => {
  if (array) {
    return array
      .reduce((acc, obj, index) => {
        const arrFromObj = Object.entries(obj)
        const [key, value] = arrFromObj[0]
        const [name, values] = arrFromObj[1]
        return `${acc}${paramName}[${index}].${key}=${value}&${values
          // eslint-disable-next-line no-shadow
          .reduce((total, value, i) => `${total}${paramName}[${index}].${name}[${i}]=${value}&`, '')}`
      }, '')
      .slice(0, -1) // removes the edge "&"
  }
  return ''
}

export default class Request {
  constructor(args) {
    this.rootStore = args.rootStore
  }

  getHeaders = () => {
    const { profileStore, authStore } = this.rootStore

    let headers = {
      'Content-Type': 'application/json',
    }

    const { auth } = authStore

    if (auth && auth.accessToken) {
      headers = {
        ...headers,
        Authorization: `${auth.tokenType || 'Bearer'} ${auth.accessToken}`,
        RoleId: profileStore.currentRoleId,
        UserId: profileStore.userId,
      }
    }

    return headers
  }

  requestFactory = (url, method, config = {}) => new Promise((resolve, reject) => {
    this.rootStore.authStore.checkTokenValid().then((valid) => {
      const headers = this.getHeaders()

      if (valid) {
        axios({
          baseURL: `${this.rootStore.configStore.apiEndpoint}/${this.rootStore.configStore.version}`,
          url,
          method,
          headers,
          withCredentials: window.env.REACT_APP_IS_WITH_CREDENTIALS,
          ...config,
        })
          .then((data) => {
            if (data.type < 0) {
              const error = {
                title: 'Error',
                description: data.message || 'An unknown error occurred',
                type: data.type,
                code: data.code,
              }
              reject(error)
            } else {
              resolve(data)
            }
          })
          .catch((err) => {
            if (err) {
              if (err.data) {
                const {
                  data: { error: errorTitle, error_description: errorDescription },
                } = err
                if (errorTitle && errorDescription) {
                  const errorMessage = {
                    status: err.status,
                    title: errorTitle,
                    description: errorDescription,
                  }
                  reject(errorMessage)
                }
              }

              const error = {
                status: err.status,
                title: 'Error',
                description: err.message,
              }
              reject(error)
            }

            const defaultError = {
              title: 'Error',
              description: 'An unknown error occurred',
            }
            reject(defaultError)
          })
      }
    })
  })

  get = (url, config) => this.requestFactory(url, 'get', config)

  post = (url, data = {}, config) => this.requestFactory(url, 'post', { data, ...config })

  put = (url, data = {}, config) => this.requestFactory(url, 'put', { data, ...config })

  patch = (url, data = {}, config) => this.requestFactory(url, 'patch', { data, ...config })

  del = (url, config) => this.requestFactory(url, 'delete', config)

  downloadCsv = (url, fileName, config = {}) => {
    const headers = this.getHeaders()
    headers['Content-Type'] = 'text/csv'
    return new Promise((resolve, reject) => {
      axios
        .create({ headers: { Accept: 'text/csv' } })({
          url: `${this.rootStore.configStore.apiEndpoint}/${this.rootStore.configStore.version}${url}`,
          ...config,
          headers,
        })
        .then(({ data }) => {
          if (data.type <= 0) {
            reject(data)
          } else {
            const blob = new Blob([data], { type: 'text/csv;charset=utf-8' })
            FileSaver.saveAs(blob, fileName || 'dowload.csv')
            resolve(data)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  downloadPdf = (url, fileName, config = {}) => {
    const headers = this.getHeaders()
    headers['Content-Type'] = 'application/pdf'
    return new Promise((resolve, reject) => {
      axios
        .create()({
          url: `${this.rootStore.configStore.apiEndpoint}/${this.rootStore.configStore.version}${url}`,
          ...config,
          responseType: 'blob',
          headers,
        })
        .then(({ data }) => {
          if (data.type <= 0) {
            reject(data)
          } else {
            const blob = new Blob([data], { type: 'application/pdf' })
            FileSaver.saveAs(blob, fileName || 'dowload.pdf')
            resolve(data)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
