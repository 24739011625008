import { action, computed, observable } from 'mobx'
import { persist } from 'mobx-persist'
import moment from 'moment'
import forOwn from 'lodash/forOwn'
import { Mutex } from 'async-mutex'
import resettableMixin from '../resettableMixin'

@resettableMixin
class AuthStore {
  @persist('object') @observable auth = { accessToken: '' }
  @observable loginLoading = false
  @observable registerLoading = false
  @observable requestResetPasswordLoading = false
  @observable resetPasswordLoading = false
  @observable forgotUsernameLoading = false

  @computed get isAuthenticated() {
    return !!this.auth.accessToken && this.rootStore.profileStore.isValidUser
  }

  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @observable mutex = new Mutex()

  @action.bound
  async login(data, redirectPath) {
    this.loginLoading = true
    try {
      const res = await this.rootAPI.authAPI.login(data)

      this.auth = {
        accessToken: res.access_token,
        refreshToken: res.refresh_token,
        expiresIn: res.expires_in,
        tokenType: res.token_type,
        timestamp: new Date(),
      }

      await this.rootStore.profileStore.getContext()

      if (redirectPath) {
        this.rootStore.routingStore.push(redirectPath)
      }
    } catch (err) {
      if (err && err.status === 400) {
        this.rootStore.errorsStore.addError({
          status: 400,
          title: 'Error',
          description: 'Invalid username or password',
        })
      } else {
        this.rootStore.errorsStore.addError(err)
      }
    } finally {
      this.loginLoading = false
    }
  }

  @action.bound
  logout() {
    forOwn(this.rootStore, (value) => {
      if (value && typeof value.reset === 'function') {
        value.reset()
      }
    })

    window.location.reload()
  }

  @action.bound
  async register(data) {
    this.registerLoading = true
    try {
      await this.rootAPI.authAPI.register(data)
      await this.login({
        username: data.userName,
        password: data.password,
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.registerLoading = false
    }
  }

  @action.bound
  async requestResetPassword(data, callback) {
    this.requestResetPasswordLoading = true
    try {
      await this.rootAPI.authAPI.requestResetPassword(data)
      callback()
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.requestResetPasswordLoading = false
    }
  }

  @action.bound
  async resetPassword(data, callback) {
    this.resetPasswordLoading = true
    try {
      await this.rootAPI.authAPI.resetPassword(data)
      callback()
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.resetPasswordLoading = false
    }
  }

  @action.bound
  async refreshToken() {
    let success = false

    try {
      const res = await this.rootAPI.authAPI.refreshToken(this.auth.refreshToken)

      if (res) {
        this.auth = {
          accessToken: res.access_token,
          refreshToken: res.refresh_token,
          expiresIn: res.expires_in,
          tokenType: res.token_type,
          timestamp: new Date(),
        }

        success = true
      }
    } catch (err) {
      if (err) {
        if (err.status === 401 || err.status === 400) {
          this.logout()
        } else {
          this.rootStore.errorsStore.addError(err)
        }
      }
    }

    return success
  }

  @action.bound
  async checkTokenValid() {
    let valid = true
    await this.mutex.runExclusive(async () => {
      const { timestamp, expiresIn } = this.auth
      if (timestamp && expiresIn) {
        const loginTime = moment(timestamp)
        const currentTime = moment()

        const duration = moment.duration(currentTime.diff(loginTime))
        const seconds = duration.asSeconds()

        if (seconds > expiresIn) {
          valid = await this.refreshToken()
        }
      }
    })

    return valid
  }

  @action.bound
  async requestForgottenUsername(data) {
    const { authAPI } = this.rootAPI
    const { errorsStore, routingStore } = this.rootStore

    this.forgotUsernameLoading = true

    try {
      await authAPI.requestForgottenUsername(data)

      routingStore.push('forgot-username-sent')
    } catch (error) {
      errorsStore.addError(error)
    } finally {
      this.forgotUsernameLoading = false
    }
  }
}

export default AuthStore
