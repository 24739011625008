import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'

export const DragDropCustomComponent = (
  {
    isDraggingColor,
    isNotDraggingColor,
    isDraggingOverColor,
    isNotDraggingOverColor,
    items,
    onDragEnd,
    renderItem,
  },
) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    color: isDragging ? isDraggingColor : isNotDraggingColor,
    ...draggableStyle,
  })

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? isDraggingOverColor : isNotDraggingOverColor,
  })

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            className="dnd-items-holder"
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
              >
                {(pro, snap) => (
                  <div
                    ref={pro.innerRef}
                    {...pro.draggableProps}
                    {...pro.dragHandleProps}
                    style={getItemStyle(
                      snap.isDragging,
                      pro.draggableProps.style,
                    )}
                    className="dnd-item"
                  >
                    {renderItem(item, index)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

DragDropCustomComponent.propTypes = {
  onDragEnd: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  items: MobxPropTypes.arrayOrObservableArray.isRequired,
  isDraggingColor: PropTypes.string,
  isNotDraggingColor: PropTypes.string,
  isDraggingOverColor: PropTypes.string,
  isNotDraggingOverColor: PropTypes.string,
}

DragDropCustomComponent.defaultProps = {
  isDraggingColor: '',
  isNotDraggingColor: '',
  isDraggingOverColor: '',
  isNotDraggingOverColor: '',
}
