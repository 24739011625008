import React from 'react'
import { Select } from 'antd'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import TestsMenuHeader from '../../TestsMenuItem/TestsMenuHeader'

const { Option, OptGroup } = Select

const TestsReleaseMenu = ({ draftReleases, plannedReleases, recentlyReleases }) => (
  <div className="tests-release-menu-ipad">
    <TestsMenuHeader name="RELEASE" bottomLineColor="blue" />
    <Select defaultValue={draftReleases[0].key} style={{ width: '100%' }}>
      <OptGroup label="Draft">
        {
          draftReleases.map(
            draftRelease => (
              <Option key={draftRelease.id} value={draftRelease.id}>{draftRelease.name}</Option>
            ),
          )
        }
      </OptGroup>
      <OptGroup label="Planned">
        {
          plannedReleases.map(
            plannedRelease => (
              <Option
                key={plannedRelease.id}
                value={plannedRelease.id}
              >
                {plannedRelease.name}
              </Option>
            ),
          )
        }
      </OptGroup>
      <OptGroup label="Recent">
        {
          recentlyReleases.map(
            recentlyRelease => (
              <Option
                key={recentlyRelease.id}
                value={recentlyRelease.id}
              >
                {recentlyRelease.name}
              </Option>
            ),
          )
        }
      </OptGroup>
    </Select>
  </div>
)

TestsReleaseMenu.propTypes = {
  draftReleases: MobxPropTypes.arrayOrObservableArray.isRequired,
  plannedReleases: MobxPropTypes.arrayOrObservableArray.isRequired,
  recentlyReleases: MobxPropTypes.arrayOrObservableArray.isRequired,
}

export default TestsReleaseMenu
