import React from 'react'
import { Button, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import { useStores } from 'flynk.app.web.core.components/helpers/common'
import { TestInformation } from '../../components/Tests'
import { TestObjectType } from '../../constants/test'

const TestCaseContainer = () => {
  // <div className="section-wrapper-test-case">
  const {
    testStore: {
      selectedTestCase,
      selectedTestCaseDetail,
    },
  } = useStores()
  return (
    <>
      <TestInformation className="test-scenario-wrapper" testObjectType={TestObjectType.TestCase} data={selectedTestCase} tableData={selectedTestCaseDetail?.testStepVersions || []} />

      <Row justify="center">
        <Col>
          <Button className="test-page-action">DISCARD CHANGES</Button>
        </Col>
        <Col>
          <Button className="ml-10 test-page-action">Save</Button>
        </Col>
        <Col>
          <Button className="ml-10 test-page-action" type="primary">PUBLISH</Button>
        </Col>
      </Row>
    </>
  )
}

export default observer(TestCaseContainer)
