import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

window.renderQMS = (containerId, history, resource) => {
  ReactDOM.render(
    <App history={history} resource={resource} />,
    document.getElementById(containerId),
  )
  serviceWorker.unregister()
  // init()
}

window.unmountQMS = (containerId) => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId))
}

if (!document.getElementById('QMS-container')) {
  ReactDOM.render(<App />, document.getElementById('root'))
  serviceWorker.unregister()
  // init()
}
