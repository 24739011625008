import Request from '../flynk.app.web.core.data/helpers/requests'
import TestAPI from './TestAPI'
import AuthAPI from '../flynk.app.web.core.data/api/AuthAPI'
import OrganisationAPI from '../flynk.app.web.core.data/api/OrganisationAPI'

export default class RootAPI {
  constructor(args) {
    const { rootStore } = args
    const request = new Request({ rootStore })

    this.testAPI = new TestAPI({ request, rootStore })
    this.authAPI = new AuthAPI({ request, rootStore })
    this.organisationAPI = new OrganisationAPI({ request, rootStore })
  }
}
