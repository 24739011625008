import { action, observable } from 'mobx'
import BaseAuthStore from 'flynk.app.web.core.data/stores/Auth/AuthStore'

export default class AuthStore extends BaseAuthStore {
  @observable isLoadedUserContext = false

    @action.bound
  async setAuth(auth) {
    try {
      this.auth = auth
      await this.rootStore.profileStore.getContext()
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.isLoadedUserContext = true
    }
  }
}
