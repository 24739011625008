import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from 'flynk.app.web.core.components/helpers/common'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TestsMenuFormModal from './TestsMenuFormModal'

const TestsMenuHeader = ({ name, bottomLineColor, canAddNewItem, formName }) => {
  const {
    testStore: {
      isShowAddScenarioFormModal,
      toggleAddScenarioFormModal,
      isShowAddSuiteFormModal,
      toggleAddSuiteFormModal,
      isShowAddCaseFormModal,
      toggleAddCaseFormModal,
    },
  } = useStores()

  const isScenario = formName === 'scenario'
  const isSuite = formName === 'suite'
  const isCase = formName === 'case'

  const handleClick = () => {
    if (isScenario) {
      toggleAddScenarioFormModal()
    } else if (isSuite) {
      toggleAddSuiteFormModal()
    } else if (isCase) {
      toggleAddCaseFormModal()
    }
  }

  const BottomLineClass = {
    blue: 'blue-bg',
    white: 'white-bg',
  }
  const bottomLineCssClass = `tests-menu-header-bottom-line ${BottomLineClass[bottomLineColor]}`
  return (
    <>
      <div className="tests-menu-header-container">
        <span className="tests-menu-header">{name}</span>
        {canAddNewItem && <Button onClick={handleClick} type="link" icon={<FontAwesomeIcon style={{ fontSize: 20 }} icon={['fal', 'plus']} />} size="small" />}
        {canAddNewItem && isScenario && isShowAddScenarioFormModal && <TestsMenuFormModal formName={formName} name="Scenario" action="create" />}
        {canAddNewItem && isSuite && isShowAddSuiteFormModal && <TestsMenuFormModal formName={formName} name="Suite" action="create" />}
        {canAddNewItem && isCase && isShowAddCaseFormModal && <TestsMenuFormModal formName={formName} name="Case" action="create" />}
      </div>
      <div className={bottomLineCssClass} />
    </>
  )
}

TestsMenuHeader.propTypes = {
  name: PropTypes.string.isRequired,
  bottomLineColor: PropTypes.string.isRequired,
  canAddNewItem: PropTypes.bool,
  formName: PropTypes.string,
}

TestsMenuHeader.defaultProps = {
  canAddNewItem: false,
  formName: '',
}

export default observer(TestsMenuHeader)
