import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BREAK_POINTS_NUMBERS } from 'flynk.app.web.core.components/constants'
import { observer, inject } from 'mobx-react'
import { MobileTestCaseContainer } from '../containers/Tests/mobileUI'

@inject(stores => ({
  getSoftwareProducts: stores.testStore.getSoftwareProducts,
}))
class TestCaseMobilePage extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      goBack: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }).isRequired,
    }).isRequired,
    getSoftwareProducts: PropTypes.func.isRequired,
  }

  state = {
    isMobile: window.innerWidth < BREAK_POINTS_NUMBERS.sm,
  }

  componentDidMount() {
    const { getSoftwareProducts } = this.props
    const {
      match: { params: { id } },
    } = this.props
    getSoftwareProducts(id)
    window.addEventListener('resize', this.handleResize)
  }

  componentDidUpdate() {
    const {
      history: { push },
      match: { params: { id } },
    } = this.props

    if (!this.state.isMobile) {
      if (id) {
        push(`/tests/${id}`)
      } else {
        push('/tests')
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    const isMobile = window.innerWidth < BREAK_POINTS_NUMBERS.sm
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile })
    }
  }

  render() {
    const { history, match } = this.props
    return (
      <MobileTestCaseContainer history={history} match={match} />
    )
  }
}

export default observer(TestCaseMobilePage)
