import React from 'react'
import { PropTypes as MobxPropTypes, observer } from 'mobx-react'

import TestsMenuHeader from '../TestsMenuItem/TestsMenuHeader'
import TestsMenuItems from '../TestsMenuItem/TestsMenuItems'

const TestsProductMenu = ({ products }) => (
  <div className="tests-product-menu">
    <TestsMenuHeader name="PRODUCT" bottomLineColor="blue" />
    <TestsMenuItems items={products} selectedBgColor="blue" />
  </div>
)

TestsProductMenu.propTypes = {
  products: MobxPropTypes.arrayOrObservableArray.isRequired,
}

export default observer(TestsProductMenu)
