import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import { useStores } from 'flynk.app.web.core.components/helpers/common'
import { observer } from 'mobx-react'
import { useParams } from 'react-router'
import TestScenarioContainer from '../TestScenarioContainer'
import TestCaseContainer from '../TestCaseContainer'
import TestStepDetailModalContainer from '../TestStepDetailModalContainer'
import { TestCategory } from '../../../constants/test'

const TabletTestsContainer = (props) => {
  const {
    testStore: {
      testStepDetailModalOpened,
      getSoftwareProducts,
      testCategoryMode,
    },
  } = useStores()
  const { id } = useParams()

  useEffect(() => {
    getSoftwareProducts(id)
  }, [])

  return (
    <>
      <Row>
        <Col span={24} className="section-wrapper-test-case">
          {testCategoryMode === TestCategory.CASE ?
            <TestCaseContainer {...props} /> :
            <TestScenarioContainer {...props} />}
        </Col>
      </Row>
      {testStepDetailModalOpened && (<TestStepDetailModalContainer />)}
    </>
  )
}

export default observer(TabletTestsContainer)
