export const TestObjectType = {
  TestScenario: 1,
  TestCase: 2,
  TestSuite: 3,
}

export const TestCategory = {
  COMPOSITION: 1,
  CASE: 2,
}
