import React, { useState } from 'react'
import { Row, Typography, Table } from 'antd'

import { PropTypes as MobxPropTypes } from 'mobx-react'

import { SimpleDropDown } from '../SimpleElements'

const { Text, Title } = Typography

const AvailableTestCases = ({ data }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const columns = [
    {
      title: '',
      dataIndex: 'name',
      width: '40%',
    },
    {
      title: 'Last Revised',
      dataIndex: 'lastRevised',
      width: '25%',
    },
    {
      title: 'By',
      dataIndex: 'by',
      width: '20%',
    },
    {
      title: 'Revision',
      dataIndex: 'revisions',
      render: revisions => <SimpleDropDown data={revisions} defaultValue={revisions[0]} style={{ width: '90%' }} />,
      width: '15%',
    },
  ]

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideSelectAll: true,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = []
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return false
            }
            return true
          })
          setSelectedRowKeys(newSelectedRowKeys)
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = []
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return true
            }
            return false
          })
          setSelectedRowKeys(newSelectedRowKeys)
        },
      },
    ],
  }

  return (
    <>
      <Row className="section-header" align="middle">
        <Title level={5} className="mr-10" type="secondary"> AVAILABLE CASES </Title>
      </Row>
      <Row>
        <Text type="secondary" className="mt-10">
          Some description about this test scenario and what it covers
        </Text>
      </Row>
      <Table rowKey={record => record.id} className="available-test-case-table" rowSelection={rowSelection} columns={columns} dataSource={data} />
    </>)
}

AvailableTestCases.propTypes = {
  data: MobxPropTypes.arrayOrObservableArray.isRequired,
}

export default AvailableTestCases
