import React from 'react'
import { Modal, Form, Input, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useStores } from 'flynk.app.web.core.components/helpers/common'

import TestsMenuFormModalHeader from './TestsMenuFormModalHeader'

const { TextArea } = Input

const TEST_FORM_MODAL = {
  FormModalHeaderText: {
    create: 'Create',
    update: 'Update',
    delete: 'Delete',
  },
  FormHeaderText: {
    create: 'Create a new',
    update: 'Update',
    delete: 'Delete',
  },
}

const TestsMenuFormModal = ({ name, formName, action }) => {
  const {
    testStore: {
      toggleAddScenarioFormModal,
      toggleAddSuiteFormModal,
      toggleAddCaseFormModal,
    },
  } = useStores()

  const isScenario = formName === 'scenario'
  const isSuite = formName === 'suite'
  const isCase = formName === 'case'

  const handleCancel = () => {
    if (isScenario) {
      toggleAddScenarioFormModal()
    } else if (isSuite) {
      toggleAddSuiteFormModal()
    } else if (isCase) {
      toggleAddCaseFormModal()
    }
  }

  const onFinish = () => {
    // handle onFinish
  }

  const onFinishFailed = () => {
    // handle on FinishFailed
  }

  const { FormHeaderText, FormModalHeaderText } = TEST_FORM_MODAL

  const formModalHeaderText = `${FormModalHeaderText[action]} ${name}`
  const formHeaderText = `${FormHeaderText[action]} ${name}`

  return (
    <Modal className="tests-form-modal" title="Tests Modal Form" visible onCancel={handleCancel}>
      <TestsMenuFormModalHeader name={formModalHeaderText} />
      <Button className="tests-form-btn tests-form-btn-close" onClick={handleCancel} type="link" htmlType="submit">
        <FontAwesomeIcon style={{ fontSize: 20 }} icon={['fal', 'times']} />
      </Button>
      <h3>{formHeaderText}</h3>
      <Form
        name={name}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Enter a name',
            },
          ]}
        >
          <Input placeholder="Enter a name" />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: false,
              message: 'Enter optional description',
            },
          ]}
        >
          <TextArea autoSize={{ minRows: 2, maxRows: 6 }} placeholder="Enter optional description" />
        </Form.Item>

        <Form.Item>
          <Button className="tests-form-btn" onClick={handleCancel} type="default" htmlType="submit">
            Cancel
          </Button>
          <Button className="tests-form-btn" type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>

      </Form>
    </Modal>
  )
}

TestsMenuFormModal.propTypes = {
  name: PropTypes.string.isRequired,
  formName: PropTypes.string,
  action: PropTypes.string.isRequired,
}

TestsMenuFormModal.defaultProps = {
  formName: '',
}

export default TestsMenuFormModal
