export default class TestAPI {
  constructor(args) {
    this.request = args.request
  }

  getSoftwareProductsByOrganizationsId = id => this.request.get(`/Organisation/${id}/SoftwareProducts`)

  getSoftwareProductsTestSuitesById = id => this.request.get(`/SoftwareProduct/${id}/TestSuites`)

  getSoftwareProductsTestCasesById = id => this.request.get(`/SoftwareProduct/${id}/TestCases`)

  getSoftwareProductsTestScenariosById = id => this.request.get(`/SoftwareProduct/${id}/TestScenarios`)

  getTestScenariosByVersionId = versionId => this.request.get(`/TestScenarios/Version/${versionId}`)

  getTestCasesByVersionId = versionId => this.request.get(`/TestCases/Version/${versionId}`)
}
