import React from 'react'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'

const TestsMenuSearch = () => (
  <Input className="test-menu-search" suffix={<SearchOutlined />} />
)

export default observer(TestsMenuSearch)
