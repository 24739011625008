import React from 'react'
import { Row, Col, Typography } from 'antd'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

const { Text, Title } = Typography

const TestSectionHeader = ({ title, isDirty, data, isSimpleHeader }) => (
  <>
    <Row className="section-header">
      <Col span={16}>
        <Row align="middle">
          <Title level={5} className="mr-10" type="secondary">{!isSimpleHeader && title}</Title>
          <Text className="test-note-text"> {data.name}</Text>
        </Row>
      </Col>
      <Col span={8}>
        <Row align="middle" justify="end">
          {isDirty && <Title level={5} type="warning">EDITED</Title> }
          <Text type="secondary" className="ml-10 test-note-text"> { !isSimpleHeader && 'Version:'} d</Text>
        </Row>
      </Col>
    </Row>
    <Row>
      <Text type="secondary" className="mt-10">
        { data.description }
      </Text>
    </Row>
  </>
)

TestSectionHeader.propTypes = {
  title: PropTypes.string,
  isDirty: PropTypes.bool,
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes,
  }).isRequired,
  isSimpleHeader: PropTypes.bool,
}

TestSectionHeader.defaultProps = {
  title: '',
  isDirty: false,
  isSimpleHeader: false,
}

export default observer(TestSectionHeader)
