import React from 'react'
import { withRouter, Switch } from '../flynk.app.web.core.components/router'
import RouteWrapper from './RouteWrapper'
import { TestPage, TestStepMobilePage, TestCaseMobilePage, TestScenarioMobilePage } from '../pages'
import AuthenticatedMultipleNavBarLayout from '../layouts/AuthenticatedMultipleNavBarLayout'

const Routes = () => (
  <Switch>
    <RouteWrapper
      layout={AuthenticatedMultipleNavBarLayout}
      exact
      path="/tests/:id/teststep"
      component={TestStepMobilePage}
      docTitle="Tests"
    />
    <RouteWrapper
      layout={AuthenticatedMultipleNavBarLayout}
      exact
      path="/tests/:id/testcase"
      component={TestCaseMobilePage}
      docTitle="Tests"
    />
    <RouteWrapper
      layout={AuthenticatedMultipleNavBarLayout}
      exact
      path="/tests/:id/testscenario"
      component={TestScenarioMobilePage}
      docTitle="Tests"
    />
    <RouteWrapper
      layout={AuthenticatedMultipleNavBarLayout}
      exact
      path="/tests/:id"
      component={TestPage}
      docTitle="Tests"
    />
    <RouteWrapper
      layout={AuthenticatedMultipleNavBarLayout}
      path="/tests"
      component={TestPage}
      docTitle="Tests"
    />
  </Switch>
)

export default withRouter(Routes)
