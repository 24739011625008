import React from 'react'
import { Space } from 'antd'

import { useStores } from 'flynk.app.web.core.components/helpers/common'

import TestsMenuHeader from '../TestsMenuItem/TestsMenuHeader'
import TestsMenuHeaderLv2 from '../TestsMenuItem/TestsMenuHeaderLv2'
import TestsMenuItems from '../TestsMenuItem/TestsMenuItems'

const TestsReleaseMenu = () => {
  const {
    testStore: {
      draftReleases,
      plannedReleases,
      recentlyReleases,
    },
  } = useStores()

  return (
    <>
      <TestsMenuHeader name="RELEASE" bottomLineColor="blue" />

      <Space direction="vertical" size={10} style={{ width: '100%' }}>

        <TestsMenuHeaderLv2 name="Draft" />
        <TestsMenuItems items={draftReleases} selectedBgColor="blue" />

        <TestsMenuHeaderLv2 name="Planned" />
        <TestsMenuItems items={plannedReleases} selectedBgColor="blue" />

        <TestsMenuHeaderLv2 name="Recent" />
        <TestsMenuItems items={recentlyReleases} selectedBgColor="blue" />

      </Space>
    </>
  )
}

export default TestsReleaseMenu
