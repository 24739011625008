import React from 'react'
import { Tabs } from 'antd'

import { useWindowSize } from 'flynk.app.web.core.components/helpers'
import { BREAK_POINTS_NUMBERS } from 'flynk.app.web.core.components/constants'
import { useStores } from 'flynk.app.web.core.components/helpers/common'
import { observer } from 'mobx-react'

import TestsMenuHeader from '../TestsMenuItem/TestsMenuHeader'
import TestsSuiteMenu from './TestsSuiteMenu'
import TestsScenariosMenu from './TestsScenarioMenu'
import TestsCaseMenu from './TestsCaseMenu'

import TestsProductMenu from './TestsProductReleaseDropdown/TestsProductMenu'
import TestsReleaseMenu from './TestsProductReleaseDropdown/TestsReleaseMenu'

const { TabPane } = Tabs

const TestsMenuTab = () => {
  const {
    testStore: {
      products,
      draftReleases,
      plannedReleases,
      recentlyReleases,
      switchTestCategoryMode,
    },
  } = useStores()
  const isIpad = useWindowSize(BREAK_POINTS_NUMBERS.xl - 1).isNarrow
  const onChangeMenuTab = (activeKey) => {
    switchTestCategoryMode(activeKey)
  }
  return (
    <>
      {
        isIpad ? (
          <>
            <TestsProductMenu products={products} />
            <TestsReleaseMenu
              draftReleases={draftReleases}
              plannedReleases={plannedReleases}
              recentlyReleases={recentlyReleases}
            />
          </>
        ) : (<TestsMenuHeader name="Engyn Webapp" bottomLineColor="white" />)
      }

      <Tabs className="test-menu-tab" defaultActiveKey={1} onChange={onChangeMenuTab}>
        <TabPane tab="COMPOSITION" key={1}>
          <TestsSuiteMenu />
          <TestsScenariosMenu />
        </TabPane>
        <TabPane tab="CASE" key={2}>
          <TestsCaseMenu />
        </TabPane>
      </Tabs>
    </>
  )
}

export default observer(TestsMenuTab)
