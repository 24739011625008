import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { use100vh } from 'react-div-100vh'
import { Layout } from 'antd'
import { useWindowSize } from 'flynk.app.web.core.components/helpers'
import { BREAK_POINTS_NUMBERS } from 'flynk.app.web.core.components/constants'

import SecondaryNavBarContainer from '../containers/Navigation/SecondaryNavBarContainer'
import ThirdNavBarContainer from '../containers/Navigation/ThirdNavBarContainer'

const { Content, Sider } = Layout

const AuthenticatedMultipleNavBarLayout = ({ children }) => {
  const { isNarrow: isIpad } = useWindowSize(BREAK_POINTS_NUMBERS.xl - 1)
  const { isNarrow: isMobile } = useWindowSize(BREAK_POINTS_NUMBERS.sm - 1)
  let height = use100vh() - 70
  height += 'px'
  return (
    <>
      <Layout>
        <Sider style={{ height, maxHeight: height }} width={isIpad ? 0 : 225} className="sub-nav-bar-menu sub-nav-bar-menu-second">
          <SecondaryNavBarContainer />
        </Sider>
        <Sider style={{ height, maxHeight: height }} width={isMobile ? 0 : 225} className="sub-nav-bar-menu sub-nav-bar-menu-third">
          <ThirdNavBarContainer />
        </Sider>
        <Content
          style={{ height, maxHeight: height }}
          className={isMobile ?
            'mobile-multiple-slide-bar-layout-content' : 'multiple-slide-bar-layout-content'}
        >
          {children}
        </Content>
      </Layout>
    </>
  )
}

AuthenticatedMultipleNavBarLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default observer(AuthenticatedMultipleNavBarLayout)
